export enum ERoles {
    CALENDARIO = 'CALENDARIO',
    DECLARACOES_FISCAIS = 'DECLARACOES_FISCAIS',
    GUIA_IMPOSTO = 'GUIA_IMPOSTO',
    NOTA_DE_SAIDA = 'NOTA_DE_SAIDA',
    NOTA_DE_ENTRADA = 'NOTA_DE_ENTRADA',
    DASHBOARD = 'DASHBOARD',
    INSIGHTS = 'INSIGHTS',
    FAQ = 'FAQ',
    INTEGRACAO_CONTADOR = 'INTEGRACAO_CONTADOR',
    DOWNLOAD_FISCAL = 'DOWNLOAD_FISCAL',
    MEMORIA_CALCULO = 'MEMORIA_CALCULO',
    ALTERAR_MANIFESTACAO = 'ALTERAR_MANIFESTACAO',
    ACEITE_INSIGHT = 'ACEITE_INSIGHT',
    GERENCIAR_USUARIOS = 'GERENCIAR_USUARIOS',
    EDITAR_USUARIOS = 'EDITAR_USUARIOS',
    VISUALIZAR_DETALHES_USUARIOS = 'VISUALIZAR_DETALHES_USUARIOS',
    CONSULTAR_USUARIOS = 'CONSULTAR_USUARIOS',
    CRIAR_USUARIOS = 'CRIAR_USUARIOS',
    DASHBOARD_ENTRADAS = 'DASHBOARD_ENTRADAS',
    DASHBOARD_SAIDAS = 'DASHBOARD_SAIDAS',
    DASHBOARD_AUDITOR = 'DASHBOARD_AUDITOR',
    DASHBOARD_INSIGHTS = 'DASHBOARD_INSIGHTS',
    DASHBOARD_MANIFESTACAO = 'DASHBOARD_MANIFESTACAO',
    CALENDARIO_EVENTOS = 'CALENDARIO_EVENTOS',
    CALENDARIO_EXTRACAO = 'CALENDARIO_EXTRACAO',
    MANIFESTAR = 'MANIFESTAR',
    EXTRACAO_RELATORIO = 'EXTRACAO_RELATORIO',
    SOLICITAR_RELATORIO = 'SOLICITAR_RELATORIO',
    DOWNLOAD_NOTA = 'DOWNLOAD_NOTA',
    EFETUAR_CONVERSAO = 'EFETUAR_CONVERSAO',
    CONSULTA_CONVERSAO = 'CONSULTA_CONVERSAO',
    VER_FORNECEDOR_FINALIDADE = 'VER_FORNECEDOR_FINALIDADE',
    VER_CFOP = 'VER_CFOP',
    EDIT_FORNECEDOR_FINALIDADE = 'EDIT_FORNECEDOR_FINALIDADE',
    EDITAR_CFOP = 'EDITAR_CFOP',
    VENDAS_REJEICOES = 'VENDAS_REJEICOES',
    VENDAS_EMISSOES = 'VENDAS_EMISSOES',
    OBRIGACOES_FISCAIS = 'OBRIGACOES_FISCAIS',
    VENDAS_CONTINGENCIA = 'VENDAS_CONTINGENCIA',
    VENDAS_INUTILIZACOES = 'VENDAS_INUTILIZACOES',
    VENDAS_DOWNLOAD = 'VENDAS_DOWNLOAD',
    VISAO_SAIDA = 'VISAO_SAIDA',
    INCONSISTENCIAS_ENTRADAS = 'INCONSISTENCIAS_ENTRADAS',
    VACINA_NOTA = 'VACINA_NOTA',
    COL_AUDITOR = 'COL_AUDITOR',
    INSIGHTS_ENTRADA = 'INSIGHTS_ENTRADA',
    INSIGHTS_APROVADAS = 'INSIGHTS_APROVADAS',
    INSIGHTS_NAPROVADAS = 'INSIGHTS_NAPROVADAS',
    INSIGHTS_CONSULTA = 'INSIGHTS_CONSULTA',
}

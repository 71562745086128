import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class UserUtilsService {

  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  public findRole(role: string) {
    return this.authenticationService.userValue.roles.includes(role);
  }
}
